define("discourse/plugins/discourse-ai/discourse/lib/utilities", ["exports", "@ember/template", "discourse/lib/utilities"], function (_exports, _template, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IMAGE_MARKDOWN_REGEX = void 0;
  _exports.jsonToHtml = jsonToHtml;
  const IMAGE_MARKDOWN_REGEX = _exports.IMAGE_MARKDOWN_REGEX = /!\[(.*?)\|(\d{1,4}x\d{1,4})(,\s*\d{1,3}%)?(.*?)\]\((upload:\/\/.*?)\)(?!(.*`))/g;
  function jsonToHtml(json) {
    if (typeof json !== "object") {
      return (0, _utilities.escapeExpression)(json);
    }
    let html = "<ul>";
    for (let key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      html += "<li>";
      if (typeof json[key] === "object" && Array.isArray(json[key])) {
        html += `<strong>${(0, _utilities.escapeExpression)(key)}:</strong> ${jsonToHtml(json[key])}`;
      } else if (typeof json[key] === "object") {
        html += `<strong>${(0, _utilities.escapeExpression)(key)}:</strong> <ul><li>${jsonToHtml(json[key])}</li></ul>`;
      } else {
        let value = json[key];
        if (typeof value === "string") {
          value = (0, _utilities.escapeExpression)(value);
          value = value.replace(/\n/g, "<br>");
        }
        html += `<strong>${(0, _utilities.escapeExpression)(key)}:</strong> ${value}`;
      }
      html += "</li>";
    }
    html += "</ul>";
    return (0, _template.htmlSafe)(html);
  }
});