define("discourse/plugins/discourse-ai/discourse/admin/models/ai-tool", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CREATE_ATTRIBUTES = ["id", "name", "description", "parameters", "script", "summary", "enabled"];
  class AiTool extends _rest.default {
    createProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    updateProperties() {
      return this.getProperties(CREATE_ATTRIBUTES);
    }
    workingCopy() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      attrs.parameters = attrs.parameters || [];
      return AiTool.create(attrs);
    }
  }
  _exports.default = AiTool;
});